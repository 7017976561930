<template>
  <div
    v-if="authUser"
    class="profile-form bg-white rounded shadow col-lg-8 p-2 m-auto"
  >
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">{{ title || "Personal information" }}</h4>
    </div>
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1" @submit.prevent>
      <b-row v-if="payload && authUser && authUser.id === payload.id">
        <b-col cols="12" md="6" lg="6" class="my-1">
          <b-button
            class="w-100"
            variant="google"
            :disabled="!!authUser.google_id"
            @click="onConnectAccount('google')"
          >
            Connect Google account
            <Icon
              style="margin-left: 5px; margin-bottom: 2px"
              icon="fe:google"
            />
          </b-button>
        </b-col>

        <b-col cols="12" md="6" lg="6" class="my-1">
          <b-button
            class="w-100 bordered"
            variant="light"
            :disabled="!!authUser.paypal_id"
            @click="onConnectAccount('paypal')"
          >
            Connect PayPal account
            <Icon
              style="margin-left: 5px; margin-bottom: 2px"
              icon="logos:paypal"
            />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2 mb-2">
        <b-col cols="12" md="5" lg="4" class="mr-1">
          <b-form-group label="Name" label-for="Name">
            <b-form-input id="name" v-model="payload.name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="5" lg="4">
          <b-form-group label="EPG timezone" label-for="timezone">
            <v-select
              v-model="payload.epg_timezone"
              :options="timezones"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2 d-flex flex-wrap justify-content-between">
        <b-col cols="12" md="6" lg="4" class="mr-1">
          <b-form-group label-for="is_epg_xml_gzip">
            <b-form-checkbox
              :checked="!!payload.is_epg_xml_gzip"
              name="is_epg_xml_gzip"
              @change="payload.is_epg_xml_gzip = !payload.is_epg_xml_gzip"
            >
              Prefer GZIP instead of plain XML
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col v-if="authUser.is_admin" cols="12" md="6" lg="4" class="mr-1">
          <b-form-group label-for="is_auto_flag">
            <b-form-checkbox
              :checked="!!payload.auto_flag"
              name="is_auto_flag"
              @change="payload.auto_flag = !payload.auto_flag"
            >
              Auto flag
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4" class="mr-1">
          <b-form-group label-for="is_epg_xml_http">
            <b-form-checkbox
              :checked="!!payload.is_epg_xml_http"
              name="is_epg_xml_http"
              @change="payload.is_epg_xml_http = !payload.is_epg_xml_http"
            >
              HTTP for EPG
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col v-if="authUser.is_admin" cols="12" md="6" lg="4" class="mr-1">
          <b-form-group label-for="is_whitelisted">
            <b-form-checkbox
              :checked="!!payload.whitelisted"
              name="is_whitelisted"
              @change="payload.whitelisted = !payload.whitelisted"
            >
              Whitelisted
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4" class="mr-1">
          <b-form-group label-for="email_subscription">
            <b-form-checkbox
              :checked="!!payload.email_subscription"
              name="email_subscription"
              @change="payload.email_subscription = !payload.email_subscription"
            >
              Email subscription
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="px-1">
        <b-button
          class="col-7 btn btn-success btn-xs-block waves-effect waves-float waves-light h-75"
          @click="$emit('save', payload)"
        >
          Save
        </b-button>
        <slot name="delete-button"></slot>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { timezones } from "@/libs/timzone";
import { apiErrorHandler } from "@/services/utils/util";
import { mapActions } from "vuex";
import { Icon } from "@iconify/vue2";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BButton,
    Icon,
  },
  props: {
    user: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      payload: { ...this.user },
    };
  },
  setup() {
    return {
      timezones,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
  },
  methods: {
    ...mapActions({
      connectSocialAccount: "auth/connectSocialAccount",
    }),

    async onConnectAccount(provider) {
      try {
        const data = await this.$auth.authenticate(provider);
        await this.connectSocialAccount({ provider, data });
      } catch (e) {
        apiErrorHandler(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.profile-form .custom-control-label {
  white-space: nowrap;
}
</style>
