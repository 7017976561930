<template>
  <b-card
    no-body
    class="border-primary cols col-lg-4 col-md-11 col-sm-11 m-auto p-0"
  >
    <b-card-header
      class="d-flex justify-content-center align-items-center pt-75 pb-25"
    >
      <h5 class="mb-1">Current plan</h5>
    </b-card-header>
    <b-card-body>
      <div
        class="d-flex flex-row justify-content-around mb-1 flex-wrap"
        v-for="service in services"
        :key="service.name"
      >
        <div><b>Channel: </b>{{ service.name }}</div>
        <div v-if="service.quantity" class="ml-1 mr-1">
          <b>Quantity: </b>{{ service.quantity }}
        </div>
        <div id="tooltip-target">
          <b>Expiration date: </b
          >{{ $dateHelpers.formatDate(service.finish_at) }}
        </div>
        <b-tooltip
          target="tooltip-target"
          triggers="hover"
          class="tooltip"
          variant="success"
        >
          Start date: {{ $dateHelpers.formatDate(service.begin_at) }}
        </b-tooltip>
      </div>
      <div
        class="d-flex flex-row justify-content-around mb-1 flex-wrap"
        v-if="!services || services.length === 0"
      >
        <div>You have no active service</div>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="toChannels"
        v-if="!hideUpgradeServices"
      >
        Upgrade channels
      </b-button>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BButton,
  BTooltip,
  BCardBody,
  BCard,
  BCardHeader,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BTooltip,
    BCardBody,
    BCard,
    BCardHeader,
  },
  props: {
    hideUpgradeServices: Boolean,
    user: Object,
  },
  methods: {
    toChannels() {
      this.$router.push({ name: "channels" });
    },
  },
  computed: {
    services() {
      return this.user?.services ?? [];
    },
  },
};
</script>

<style></style>
